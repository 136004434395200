import { Auth } from "aws-amplify";

export function redirectToLogIn(redirectPath = ''){
    const { oauth } = Auth.configure();
    let path = redirectPath
    
    if(!!window.location.pathname && !path) {
        path = window.location.pathname
        if(window.location.search) {
            path += window.location.search
        }
    }

    Auth.federatedSignIn({
        provider: oauth.idp,
        customState: (path || '/')
    });
}

export function redirectToLogOut() {
    const { userPoolWebClientId, oauth } = Auth.configure();
    window.location.assign(`https://${oauth.domain}/logout?&client_id=${userPoolWebClientId}&logout_uri=${oauth.redirectSignOut}`);
}

export function isUnauthenticated(error) {
    const status = (error.response && error.response.status) ? error.response.status : null;
    const unauthorizedErrors = ["No current user", "Request failed with status code 401"];
    return (unauthorizedErrors.includes(error) || status === 401) ? true : false;
}

export function handleUnauthenticated(error, callback) {
    if(isUnauthenticated(error)) {
        Auth.signOut();
        redirectToLogOut();
    } else {
        callback();
    }
}