import { API } from "aws-amplify";
import { ORDERS, ORDERS_STATUS, ORDER_ADDRESS_UPDATE, ORDER_ADDRESS_UPDATE_STATUS, ORDER_STATUS_UPDATE, ORDER_STATUS_UPDATE_STATUS } from "../actions-index";
import { handleUnauthenticated } from "../Authentication/util";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS } from "../util/constants";
import { buildAction } from "../util/util";

const ORDER_ID_DELIMITER = "|";
const REDEMPTION_KEY_DELIMITER = "#";

function buildOrderId(emplId, redemptionKey) {
    return `${emplId}${ORDER_ID_DELIMITER}${redemptionKey}`;
}

function splitOrderId(orderId) {
    let orderIdTokens = orderId.split(ORDER_ID_DELIMITER);
    let redemptionKey = orderIdTokens[1]
    let redemptionKeyTokens = redemptionKey.split(REDEMPTION_KEY_DELIMITER);
    let rewardYear = redemptionKeyTokens[0]
    let rewardLevel = redemptionKeyTokens[1]
    let rewardId = redemptionKeyTokens[2]
    return {emplId: orderIdTokens[0], redemptionKey, rewardId, rewardYear, rewardLevel };
}

export function getOrders(status = null) {
    return dispatch => {
        const url = (status ? `/redemptions/status/${status}` : "/redemptions");
        dispatch(buildAction(ORDERS_STATUS, ACTION_STATUS_LOADING));
        API.get(process.env.REACT_APP_API_NAME, url)
        .then(response => {
            let orders = response.data || []
            const updatedOrders = orders.map(order => {
                order.id = buildOrderId(order.emplId, order.redemptionKey);
                return order;
            });
            dispatch(buildAction(ORDERS, updatedOrders));
            dispatch(buildAction(ORDERS_STATUS, ACTION_STATUS_SUCCESS));
        })
        .catch(error => {
            console.error("Get Orders Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(ORDERS_STATUS, ACTION_STATUS_ERROR));
            });
        });
    };
}

export function updateOrderAddress(id, address) {
    return dispatch => {
        dispatch(buildAction(ORDER_ADDRESS_UPDATE_STATUS, ACTION_STATUS_LOADING));
        const request = splitOrderId(id);
        request.shippingAddress = address;
        API.patch(process.env.REACT_APP_API_NAME, "/redemption", {body: request})
        .then((response) => {
            const updatedOrder = response.data;
            updatedOrder.id = id;
            dispatch(buildAction(ORDER_ADDRESS_UPDATE, updatedOrder));
            dispatch(buildAction(ORDER_ADDRESS_UPDATE_STATUS, ACTION_STATUS_SUCCESS));
        })
        .catch(error => {
            console.error("Update Order Address Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(ORDER_ADDRESS_UPDATE_STATUS, ACTION_STATUS_ERROR));
            });
        });
    };
}

export function updateOrderStatus(id, status) {
    return dispatch => {
        dispatch(buildAction(ORDER_STATUS_UPDATE_STATUS, ACTION_STATUS_LOADING));
        const request = splitOrderId(id);
        request.redemptionStatus = status;
        API.patch(process.env.REACT_APP_API_NAME, "/redemption", {body: request})
        .then((response) => {
            dispatch(buildAction(ORDER_STATUS_UPDATE, id));
            dispatch(buildAction(ORDER_STATUS_UPDATE_STATUS, ACTION_STATUS_SUCCESS));
        })
        .catch(error => {
            console.error("Update Order Status Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(ORDER_STATUS_UPDATE_STATUS, ACTION_STATUS_ERROR));
            });
        });
    };
}