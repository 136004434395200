import React from "react";
import PropTypes from "prop-types";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import useAmplifyHub from "../../Common/hooks/useAmplifyHub";
import SignIn from "../../Authentication/containers/SignIn";
import SignOut from "../../Authentication/components/SignOut";
import Orders from "../../Order/containers/List";
import Rewards from "../../Reward/containers/List";
import CreateReward from "../../Reward/containers/Create";

const APPLICATION_TITLE = "Incentive Rewards | Ohio State";
function Routes(props) {
  const { isAuthenticated, isAuthorized } = props;
  const { redirectPath } = useAmplifyHub();
  const { pathname } = useLocation();
  
  const renderPage = (component, pageTitle = null) => {
    // show an empty page until authenticated
    // login and logout perform as normal
    if(!isAuthenticated && !["/login", "/logout"].includes(pathname)) {
      component = null;
      pageTitle = null;
    }
    // if authenticated, but not authorized, show the unauthorized page
    // login performs as normal - will redirect to the index page on success (which could potentially redirect to the unauthorized page)
    if(isAuthenticated && !isAuthorized && pathname !== "/login") {
      component = (<Unauthorized />);
      pageTitle = null;
    }

    document.title = (pageTitle ? `${pageTitle} | ${APPLICATION_TITLE}` : APPLICATION_TITLE);
    return component;  
  };

  const PageNotFound = () => {
    return (
        <div data-testid="page-not-found">
            <h2>Page Not Found</h2>
            Return to the <Link to="/">Home</Link> page.
        </div>
    );
  };

  const Unauthorized = () => {
    return (
        <div data-testid="unauthorized">
            <h2>Unauthorized</h2>
            <p>You are not authorized to access this application.</p>
        </div>
    );
  };
  
  return (
    <Switch>
      {(redirectPath && redirectPath !== "/logout") && <Redirect from={`/login`} to={redirectPath} />}
      <Route exact path="/" render={() => <Redirect to="/order/list" />} />
      <Route exact path="/login" render={() => renderPage(<SignIn />)} />
      <Route exact path="/logout" render={() => renderPage(<SignOut />)} />
      <Route exact path="/order/list" render={() => renderPage(<Orders />, "Orders")} />
      <Route exact path="/reward/list" render={() => renderPage(<Rewards />, "Rewards")} />
      <Route exact path="/reward/create" render={() => renderPage(<CreateReward />, "Create Reward")} />
      <Route render={() => renderPage(<PageNotFound />)} />
    </Switch>
  );
}

Routes.defaultProps = {
  isAuthenticated: false,
  isAuthorized: false
};

Routes.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAuthorized: PropTypes.bool
};

export default Routes;
