import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CreateReward from "../components/Create";
import { createReward, getRewardImages, REWARD_CREATE_STATUS, REWARD_AWARD_KEYS_UPLOAD, REWARD_AWARD_KEYS_UPLOAD_STATUS, REWARD_IMAGE_UPLOAD, REWARD_IMAGE_UPLOAD_STATUS, 
    uploadRewardImage, uploadRewardAwardKeys, getRewardAwardKeys, deleteRewardAwardKeysUpload } from "../../actions-index";
import { buildAction } from "../../util/util";

const mapStateToProps = (state) => {
    const { data: rewardImages = [], status: rewardImagesStatus = "" } = state.rewardImages;
    const { data: rewardImageUpload = null, status: rewardImageUploadStatus = "" } = state.rewardImageUpload;
    const { data: rewardAwardKeys = null, status: rewardAwardKeysStatus = ""} = state.rewardAwardKeys
    const { data: rewardAwardKeysUpload = null, status: rewardAwardKeysUploadStatus = ""} = state.rewardAwardKeysUpload
    return {
        rewardCreateStatus: state.rewardCreateStatus,
        rewardImages,
        rewardImagesStatus,
        rewardImageUpload,
        rewardImageUploadStatus,
        rewardAwardKeys,
        rewardAwardKeysStatus,
        rewardAwardKeysUploadStatus, 
        rewardAwardKeysUpload
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearRewardCreateStatus: () => dispatch(buildAction(REWARD_CREATE_STATUS, "")),
        clearRewardImageUpload: () => {
            dispatch(buildAction(REWARD_IMAGE_UPLOAD, null));
            dispatch(buildAction(REWARD_IMAGE_UPLOAD_STATUS, ""));
        },
        createReward: (reward) => dispatch(createReward(reward)),
        getRewardImages: () => dispatch(getRewardImages()),
        uploadRewardImage: (rewardImage) => dispatch(uploadRewardImage(rewardImage)),
        getRewardAwardKeys: (fileName) => dispatch(getRewardAwardKeys(fileName)),
        uploadRewardAwardKeys: (rewardAwardKeys) => dispatch(uploadRewardAwardKeys(rewardAwardKeys)),
        clearRewardAwardKeysUpload: (rewardAwardKeysUpload) => {
            if(rewardAwardKeysUpload) dispatch(deleteRewardAwardKeysUpload(rewardAwardKeysUpload));
            dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD, null));
            dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD_STATUS, ""));
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateReward));