import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Icon } from "osu-react-components";
import { buildAccessibleFormFeedback } from "../../util/util";
import "../styles/ShippingAddress.css";

const FORM_ID = "shippingAddressForm";
function ShippingAddress(props) {
    const { data, editable, isEditing, isSubmitting, label, onEdit, onEditCancel, onEditSubmit, testId } = props;
    const [inputInvalid, setInputInvalid] = useState({});
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const validateInput = (event) => {
        const input = event.target;
        setInputInvalid({ ...inputInvalid, [input.name]: !input.checkValidity() });
        const form = document.getElementById(FORM_ID);
        const isFormInvalid = !form.checkValidity();
        setIsSubmitDisabled(isFormInvalid);
    }

    if(!data) return null;

    const Header = (
        <Fragment>
            <span className="d-inline-block my-2">{label}</span>
            {(editable && !isEditing) &&
                <span className="d-inline-block">
                    <Button aria-label={`Edit ${label}`} aria-describedby="editShippingAddressButtonDesc" color="link" onClick={onEdit}>Edit</Button>
                    <p id="editShippingAddressButtonDesc" className="sr-only">Displays form fields that allow for editing the {label}.</p>
                </span>
            }
        </Fragment>
    )

    if(isEditing) {
        return (
            <div data-testid={testId}>
                <Form id={FORM_ID} data-testid="shipping-address-form" onSubmit={onEditSubmit}>
                    <FormGroup tag="fieldset">
                        <legend className="shipping-address-header">{Header}</legend>
                        <FormGroup>
                            <Label for="addressLine1" className="sr-only">Address Line 1</Label>
                            <Input type="text" id="addressLine1" name="addressLine1" placeholder="Address Line 1" defaultValue={data.addressLine1 || ""}
                                autoFocus={true} required invalid={inputInvalid.addressLine1} onChange={validateInput} />
                            {buildAccessibleFormFeedback("Address is required.")}
                        </FormGroup>
                        <FormGroup>
                            <Label for="addressLine2" className="sr-only">Address Line 2</Label>
                            <Input type="text" id="addressLine2" name="addressLine2" placeholder="Address Line 2" defaultValue={data.addressLine2 || ""} />
                        </FormGroup>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="buildingName" className="sr-only">Building Name</Label>
                                    <Input type="text" id="buildingName" name="buildingName" placeholder="Building Name" defaultValue={data.buildingName || ""} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="buildingNumber" className="sr-only">Building Number</Label>
                                    <Input type="text" id="buildingNumber" name="buildingNumber" placeholder="Building Number" defaultValue={data.buildingNumber || ""} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="city" className="sr-only">City</Label>
                                    <Input type="text" id="city" name="city" placeholder="City" defaultValue={data.city || ""} required 
                                        invalid={inputInvalid.city} onChange={validateInput} />
                                    {buildAccessibleFormFeedback("City is required.")}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="state" className="sr-only">Select State</Label>
                                    <Input type="select" id="state" name="state" defaultValue={data.state || ""} required
                                        invalid={inputInvalid.state} onChange={validateInput}>
                                        <StateSelectOptions />
                                    </Input>
                                    {buildAccessibleFormFeedback("State is required.")}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="zip" className="sr-only">Zipcode</Label>
                                    <Input type="text" id="zip" name="zip" placeholder="Zipcode" defaultValue={data.zip || ""} maxLength="10" required
                                        pattern="^[0-9]{5}(?:-[0-9]{4})?$" invalid={inputInvalid.zip} onChange={validateInput} />
                                    {buildAccessibleFormFeedback("Zipcode is required.")}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="supervisor" className="sr-only">Supervisor</Label>
                                    <Input type="text" id="supervisor" name="supervisor" placeholder="Supervisor" defaultValue={data.supervisor || ""} />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="floorNumber" className="sr-only">Floor Number</Label>
                                    <Input type="text" id="floorNumber" name="floorNumber" placeholder="Floor Number" defaultValue={data.floorNumber || ""} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label for="additionalInstructions" className="sr-only">Additional Instructions</Label>
                            <Input type="text" id="additionalInstructions" name="additionalInstructions" placeholder="Additional Instructions" defaultValue={data.additionalInstructions || ""} />
                        </FormGroup>
                        <FormGroup className="sr-only">
                            <Input type="hidden" id="deliveryOption" name="deliveryOption" defaultValue={data.deliveryOption} />
                            <Input type="hidden" id="medCenterEmployee" name="medCenterEmployee" defaultValue={data.medCenterEmployee} />
                        </FormGroup>
                    </FormGroup>
                    <div className="button-bar">
                        <Button aria-label={`Cancel edit of ${label}`} aria-describedby="cancelEditShippingAddressButtonDesc" onClick={onEditCancel}
                            disabled={isSubmitting}>
                            Cancel
                        </Button>
                        <p id="cancelEditShippingAddressButtonDesc" className="sr-only">Removes form fields that allow for editing the {label}.</p>
                        <Button type="submit" className="osu-red-btn d-inline-block" aria-label={`Submit edited ${label}`} 
                            aria-describedby="submitEditShippingAddressButtonDesc" disabled={(isSubmitDisabled || isSubmitting)}>
                            Submit
                        </Button>
                        {isSubmitting &&
                            <Fragment>
                                <div data-testid="shipping-address-processing" className="d-inline-block"><Icon type="spinner fa-spin" /> Processing</div>
                                <div aria-live="polite" className="sr-only" data-testid="shipping-address-processing-aria">Submit of edited {label} is processing.</div>
                            </Fragment>
                        }
                        <p id="submitEditShippingAddressButtonDesc" className="sr-only">Displays updated {label} when submit is successful.</p>
                    </div>
                </Form>
            </div>
        );
    } else {
        return (
            <div data-testid={testId}>
                <div className="d-flex justify-space-between shipping-address-header">{Header}</div>
                <div className="d-flex">
                    <div className="pr-4">
                        <div>{data.addressLine1}</div>
                        {data.addressLine2 && <div>{data.addressLine2}</div>}
                        <div>{data.city}, {data.state}, {data.zip}</div>
                        {data.buildingName && <div>Building Name: {data.buildingName}</div>}
                        {data.buildingNumber && <div>Building Number: {data.buildingNumber}</div>}
                    </div>
                    <div>
                        {data.medCenterEmployee && <div>Med Center Employee: {data.medCenterEmployee === "Y" ? "Yes" : "No"}</div>}
                        {data.floorNumber && <div>Floor Number: {data.floorNumber}</div>}
                        {data.supervisor && <div>Supervisor: {data.supervisor}</div>}
                        {data.additionalInstructions && <div>Additional Instructions: {data.additionalInstructions}</div>}
                    </div>
                </div>
            </div>
        );
    }
}

ShippingAddress.defaultProps = {
    data: null,
    editable: false,
    isEditing: false,
    isSubmitting: false,
    label: "Shipping Address",
    testId: null
}

ShippingAddress.propTypes = {
    data: PropTypes.shape({
        additionalInstructions: PropTypes.string,
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        buildingName: PropTypes.string,
        buildingNumber: PropTypes.string,
        city: PropTypes.string,
        deliveryOption: PropTypes.string,
        floorNumber: PropTypes.string,
        medCenterEmployee: PropTypes.string,
        state: PropTypes.string,
        supervisor: PropTypes.string,
        zip: PropTypes.string
    }),
    editable: PropTypes.bool,
    isEditing: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    label: PropTypes.string,
    onEdit: PropTypes.func,
    onEditCancel: PropTypes.func,
    onEditSubmit: PropTypes.func,
    testId: PropTypes.string
}

export default ShippingAddress;

const StateSelectOptions = () => (
    [
        <option value="" key="Select">Select State...</option>,
        <option value="AL" key="Alabama">Alabama</option>,
        <option value="AK" key="Alaska">Alaska</option>,
        <option value="AZ" key="Arizona">Arizona</option>,
        <option value="AR" key="Arkansas">Arkansas</option>,
        <option value="CA" key="California">California</option>,
        <option value="CO" key="Colorado">Colorado</option>,
        <option value="CT" key="Connecticut">Connecticut</option>,
        <option value="DE" key="Delaware">Delaware</option>,
        <option value="DC" key="District of Columbia">District of Columbia</option>,
        <option value="FL" key="Florida">Florida</option>,
        <option value="GA" key="Georgia">Georgia</option>,
        <option value="HI" key="Hawaii">Hawaii</option>,
        <option value="ID" key="Idaho">Idaho</option>,
        <option value="IL" key="Illinois">Illinois</option>,
        <option value="IN" key="Indiana">Indiana</option>,
        <option value="IA" key="Iowa">Iowa</option>,
        <option value="KS" key="Kansas">Kansas</option>,
        <option value="KY" key="Kentucky">Kentucky</option>,
        <option value="LA" key="Louisiana">Louisiana</option>,
        <option value="ME" key="Maine">Maine</option>,
        <option value="MD" key="Maryland">Maryland</option>,
        <option value="MA" key="Massachusetts">Massachusetts</option>,
        <option value="MI" key="Michigan">Michigan</option>,
        <option value="MN" key="Minnesota">Minnesota</option>,
        <option value="MS" key="Mississippi">Mississippi</option>,
        <option value="MO" key="Missouri">Missouri</option>,
        <option value="MT" key="Montana">Montana</option>,
        <option value="NE" key="Nebraska">Nebraska</option>,
        <option value="NV" key="Nevada">Nevada</option>,
        <option value="NH" key="New Hampshire">New Hampshire</option>,
        <option value="NJ" key="New Jersey">New Jersey</option>,
        <option value="NM" key="New Mexico">New Mexico</option>,
        <option value="NY" key="New York">New York</option>,
        <option value="NC" key="North Carolina">North Carolina</option>,
        <option value="ND" key="North Dakota">North Dakota</option>,
        <option value="OH" key="Ohio">Ohio</option>,
        <option value="OK" key="Oklahoma">Oklahoma</option>,
        <option value="OR" key="Oregon">Oregon</option>,
        <option value="PA" key="Pennsylvania">Pennsylvania</option>,
        <option value="RI" key="Rhode Island">Rhode Island</option>,
        <option value="SC" key="South Carolina">South Carolina</option>,
        <option value="SD" key="South Dakota">South Dakota</option>,
        <option value="TN" key="Tennessee">Tennessee</option>,
        <option value="TX" key="Texas">Texas</option>,
        <option value="UT" key="Utah">Utah</option>,
        <option value="VT" key="Vermont">Vermont</option>,
        <option value="VA" key="Virginia">Virginia</option>,
        <option value="WA" key="Washington">Washington</option>,
        <option value="WV" key="West Virginia">West Virginia</option>,
        <option value="WI" key="Wisconsin">Wisconsin</option>,
        <option value="WY" key="Wyoming">Wyoming</option>
    ]
);