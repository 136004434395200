import React from "react";

export default function Footer() {
    return (
        <footer className="footer-dk-gray">
          <div className="row no-row-margins pt-4">
              <div className="col-12 col-sm-7 d-flex">
                  <div className="ml-auto mr-auto mr-md-4 ml-md-0">
                      <div className="col-sm-12 pb-4">
                          <a href="http://osu.edu" target="_blank" rel="noreferrer">
                              <img className="osu-logo" src="https://cybersecurity.osu.edu/profiles/wcm_base/themes/wcm_omega/images/osu-logos/osu-footer-wordmark-dk-gray.svg"
                                  alt="The Ohio State University" />
                          </a>
                      </div>
                      <div className="col-sm-12 mb-4 mb-md-0 text-center text-sm-left">
                          <p>Cybersecurity</p>
                          <p>Phone: <a href="tel:+1-614-688-4357">614-688-4357</a></p>
                          <p>Email: <a href="mailto:8help@osu.edu">8help@osu.edu</a></p>
                      </div>
                  </div>
              </div>
              <div className="col-12 col-sm-5">
                  <div className="col-12 d-flex">
                      <ul className="osu-siteinfo-social mr-auto ml-auto mr-md-0">
                          <li>
                              <a href="https://twitter.com/TechOhioState" target="_blank" rel="noreferrer" className="siteinfo-social-link link-twitter">
                                  <span className="element-invisible">Twitter</span>
                                  <span className="fa fa-lg fa-twitter" />
                              </a>
                          </li>
                          <li>
                              <a href="https://www.youtube.com/user/TechOhioState" target="_blank" rel="noreferrer" className="siteinfo-social-link link-youtube">
                                  <span className="element-invisible">YouTube</span>
                                  <span className="fa fa-lg fa-youtube-play" />
                              </a>
                          </li>
                          <li>
                              <a href="https://www.linkedin.com/groups/4956310/profile" target="_blank" rel="noreferrer" className="siteinfo-social-link link-linkedin">
                                  <span className="element-invisible">LinkedIn</span>
                                  <span className="fa fa-lg fa-linkedin" />
                              </a>
                          </li>
                          <li>
                              <a href="https://plus.google.com/+TechOhioStateOCIO/posts" target="_blank" rel="noreferrer" className="siteinfo-social-link link-googleplus">
                                  <span className="element-invisible">Google+</span>
                                  <span className="fa fa-lg fa-google-plus" />
                              </a>
                          </li>
                          <li>
                              <a href="https://www.instagram.com/techohiostate/" target="_blank" rel="noreferrer" className="siteinfo-social-link link-instagram">
                                  <span className="element-invisible">Instagram</span>
                                  <span className="fa fa-lg fa-instagram" />
                              </a>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
        </footer>
    );
}