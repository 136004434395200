import { useState, useEffect } from "react";
import { Hub } from "aws-amplify";

export default function useAmplifyHub() {
	const [redirectPath, setRedirectPath] = useState("");

  useEffect(() => {
    Hub.listen(
      "auth",
      ({ payload: { event, data } }) => {
        if (event === "customOAuthState" && data) {
          setRedirectPath(data);
        }
      },
      []
    );
  });

  return {
    redirectPath
  };
}
