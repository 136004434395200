import React from "react";
import { Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import { OSUButton, OSULoading } from "osu-react-components";
import { redirectToLogIn } from "../util";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS } from "../../util/constants";

export default function SignIn(props) {
    const { setUser, user } = props;
    if(isEmpty(user.status)) setUser(true);
    const Heading = (<h2 className="sr-only">Log In</h2>);
    switch(user.status) {
        case ACTION_STATUS_LOADING:
            return (
                <div>
                    {Heading}
                    <div role="alert" className="sr-only">Logging in...</div>
                    <OSULoading text="Logging in..." />
                </div>
            );
        case ACTION_STATUS_ERROR: 
            return (
                <div>
                    {Heading}
                    <div className="d-flex flex-column align-items-center">
                        <h3>There was an error logging you in.</h3>
                        <OSUButton type="button" color="blue" ariaLabel="Retry Log In" onClick={() => redirectToLogIn()}>Log In</OSUButton>
                    </div>
                </div>
            );
        case ACTION_STATUS_SUCCESS:
            return (<Redirect to="/" />);
        default:
            return null;
    }
}