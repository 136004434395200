import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Loader from "react-loader";
import Select from "react-select";
import { Alert, Button, Media } from "reactstrap";
import { Icon } from "osu-react-components";
import ExportToCSVButton from "../../Common/components/ExportToCSVButton";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS } from "../../util/constants";
import { buildLabelValuePair } from "../../util/util";
import "../styles/List.css";

const EXPORT_FILE_NAME = "Rewards.csv";
function Rewards(props) {
    const { clearRewardUpdateStatus, getRewardsByYear, rewards, rewardsStatus, rewardUpdateStatus, toggleRewardActive } = props;
    const alertsEl = useRef(null);
    const rewardYearSelectEl = useRef(null);
    let currentYear = new Date().getFullYear();
    const years = [];
    for(let i = -5; i < 6; i++) {
        years.push(String(currentYear + i));
    }
    currentYear = String(currentYear);
    const [rewardYearSelectOptions] = useState(
        years.map(year => (buildLabelValuePair(year)))
    );
    const [rewardYearSelectedOption, setRewardYearSelectedOption] = useState(
        rewardYearSelectOptions.find(option => (option.value === currentYear))
    );
    const [rewardsTableSelectedRows, setRewardsTableSelectedRows] = useState([]);

    if(rewardsStatus === "") getRewardsByYear(rewardYearSelectedOption.value);

    // on mount
    useEffect(() => {
        const rewardYearSelect = document.getElementById("rewardYearSelect");
        if(rewardYearSelect && !rewardYearSelect.getAttribute("aria-describedby")) {
            rewardYearSelect.setAttribute("aria-describedby", "rewardYearSelectDesc"); // necessary because react-select does not support aria-describedby
        }
    }, []);

    // on rewards year select change
    useEffect(() => {
        getRewardsByYear(rewardYearSelectedOption.value);
    }, [getRewardsByYear, rewardYearSelectedOption]);

    // on reward update status change
    useEffect(() => {
        if(rewardUpdateStatus === ACTION_STATUS_ERROR) alertsEl.current.focus();
    }, [rewardUpdateStatus]);

    // on unmount
    useEffect(() => {
        return () => {
            clearRewardUpdateStatus();
        };
    }, [clearRewardUpdateStatus]);

    // table properties
    const emptyTableCellHandler = (cell) => (cell ? cell : "");
    const tableColumns = [
        { text: "Reward Id", dataField: "rewardId", sort: false, hidden: true, csvExport: false },
        { text: "Level", dataField: "rewardLevel", sort: true, attrs: () => ({ "data-header": "Level" }) },
        { text: "Image", dataField: "imageUrl", headerStyle: { textAlign: "center" }, attrs: () => ({ "data-header": "Image" }),
            formatter: (cell) => (cell === null ? null : <Media object src={encodeURI(cell)} alt="Reward Image" className="reward-image" />),
            csvFormatter: (cell) => (cell === null ? "" : cell.substring(cell.lastIndexOf("/") + 1))
        },
        { text: "Title", dataField: "title", sort: true, attrs: () => ({ "data-header": "Title" }), headerStyle:{ width: '200px' } },
        { text: "Categories", dataField: "categories", sort: false, attrs: () => ({ "data-header": "Categories" }),
            formatter: (cell) => {
                return cell && Array.isArray(cell) &&
                <>{cell.map( (item, index, cellArrray) => <span className="mt-0 mb-0" key={item}>{item}{index !== cellArrray.length - 1 && ', ' }</span>)}</>
            },
            csvFormatter: (cell) => emptyTableCellHandler(cell)
        },
        { text: "Type", dataField: "rewardType", sort: true, csvFormatter: (cell) => emptyTableCellHandler(cell),
            attrs: () => ({ "data-header": "Type" }) },
        { text: "SKU", dataField: "SKU", sort: true, headerStyle:{ width: '200px' }, attrs: () => ({ "data-header": "SKU" }), 
            csvFormatter: (cell) => emptyTableCellHandler(cell) },
        { text: "Description", dataField: "description", csvFormatter: (cell) => emptyTableCellHandler(cell),
            attrs: () => ({ "data-header": "Description" }) },
        { text: "Award Keys", dataField: "awardKeysPath", sort: true, attrs: () => ({ "data-header": "Award Keys" }),
            formatter: (cell) => (cell ? "Yes" : "No"),
            csvFormatter: (cell) => (cell ? "Yes" : "No")
        },
        { text: "Active", dataField: "active", sort: true, attrs: () => ({ "data-header": "Active" }),
            formatter: (cell) => (cell === true ? "Yes" : "No"),
            csvFormatter: (cell) => (cell === true ? "Yes" : "No")
        }
    ];
    const tableExportToCSV = {
        fileName: `${rewardYearSelectedOption.value} ${EXPORT_FILE_NAME}` 
    };
    const tableDefaultSort = [{ dataField: "rewardLevel", order: "asc" }];
    const onTableChange = (type, {sortField, sortOrder, data}) => {
        // handle sort manually so that the data is sorted when exported
        if(type === "sort") {
            const compare = (a, b) => {
                if (a === b) {
                    return 0;
                }
                else if (a == null) {
                    return 1;
                }
                else if (b == null) {
                    return -1;
                }
                else { 
                    return a > b ? 1 : -1;
                }
            };
            data.sort((a, b) => {
                return sortOrder === "asc" ? compare(a[sortField], b[sortField]) : compare(b[sortField], a[sortField]);
            });
        }
    };
    const tableSelectRow = {
        mode: "radio",
        clickToSelect: false,
        selected: rewardsTableSelectedRows,
        onSelect: (row) => setRewardsTableSelectedRows([row.rewardId]),
        selectionRenderer: ({ mode, checked, disabled, rowIndex }) => {
            const name = `selectRewardRow${rowIndex}`;
            const desc = `selectRewardRow${rowIndex}Desc`;
            return (
                <div>
                    <label htmlFor={name} className="sr-only">Select Reward</label> 
                    <input id={name} name={name} type={mode} checked={checked} disabled={disabled} aria-describedby={desc} onChange={()=>{}} />
                    <p id={desc} className="sr-only">Selects the reward row that will be targeted for reward actions.</p>
                </div>
            );
        }
    }
    const tableNoDataIndication = () => {
        if(rewardsStatus === ACTION_STATUS_ERROR) {
            return (
                <span className="osu-red">
                    <Icon type="exclamation-triangle" color="red" /> Rewards can not be retrieved at this time.
                </span>
            );
        } else {
            return "There are currently no rewards.";
        }
    };
    const hideTableButtons = (rewards.length === 0 || rewardsStatus === ACTION_STATUS_ERROR);

    const onToggleRewardActive = () => {
        if(rewardsTableSelectedRows.length !== 0 && rewardUpdateStatus !== ACTION_STATUS_LOADING) {
            const rewardId = rewardsTableSelectedRows[0];
            const reward = rewards.find(reward => reward.rewardId === rewardId);
            toggleRewardActive(rewardId, !reward.active);
        }
    };

    return (
        <div>
            <div data-testid="alerts" ref={alertsEl} tabIndex="-1" className="outline-none">
                <Alert data-testid="reward-update-error" color="danger" isOpen={rewardUpdateStatus === ACTION_STATUS_ERROR} toggle={clearRewardUpdateStatus}>
                    <b>Reward Update</b>
                    <p>An error occurred while toggling the active status of the reward. Please retry to see if that resolves the issue.</p>
                </Alert>
            </div>
            <h2 className="d-inline-block" data-testid="header">Rewards</h2>
            <div className="float-right">
                <label htmlFor="rewardYearSelect" className="display-inline-block">Year</label>:&nbsp;
                <Select id="rewardYearSelect" name="rewardYearSelect" className="display-inline-block reward-year-select" 
                    ref={rewardYearSelectEl} options={rewardYearSelectOptions} value={rewardYearSelectedOption} 
                    onChange={option => setRewardYearSelectedOption(option)} />
                <p id="rewardYearSelectDesc" className="sr-only">Changing the year selection updates the table to display rewards for the selected year.</p>
            </div>
            <Loader loaded={rewardsStatus !== ACTION_STATUS_LOADING} color="#666666">
                <ToolkitProvider bootstrap4 keyField="rewardId" columns={tableColumns} data={rewards} exportCSV={tableExportToCSV}>
                    {
                        props => (
                            <div>
                                <div>
                                    <BootstrapTable id="rewardsTable" {...props.baseProps} bordered={false} remote={{sort: true}} headerClasses="table-header"
                                        defaultSorted={tableDefaultSort} onTableChange={onTableChange} selectRow={tableSelectRow} 
                                        noDataIndication={tableNoDataIndication} />
                                    <hr />
                                </div>
                                <div className={`button-bar${hideTableButtons ? " d-none" : ""}`}>
                                    <ExportToCSVButton {...props.csvProps} label="Export Rewards" ariaDescribedby="exportToCSVButtonDesc" />
                                    <p id="exportToCSVButtonDesc" className="sr-only">Exports the rewards from the table to a CSV file.</p>
                                    <Button className="osu-red-btn reward-active-toggle-btn" aria-describedby="rewardActiveToggleButtonDesc"
                                        disabled={(rewardsTableSelectedRows.length === 0 || rewardUpdateStatus === ACTION_STATUS_LOADING)}
                                        onClick={onToggleRewardActive}>
                                        Toggle Active
                                    </Button>
                                    {rewardUpdateStatus === ACTION_STATUS_LOADING &&
                                        <Fragment>
                                            <div data-testid="rewards-update-processing" className="d-inline-block"><Icon type="spinner fa-spin" /> Processing</div>
                                            <div aria-live="polite" className="sr-only" data-testid="rewards-update-processing-aria">Reward update is processing.</div>
                                        </Fragment>
                                    }
                                    {rewardUpdateStatus === ACTION_STATUS_SUCCESS &&
                                        <div aria-live="polite" className="sr-only" data-testid="rewards-update-complete-aria">Reward update is complete.</div>
                                    }
                                    <p id="rewardActiveToggleButtonDesc" className="sr-only">
                                        Toggles the active status of the selected reward.  Disabled if no reward is selected.
                                    </p>
                                </div>
                            </div>
                        )
                    }
                </ToolkitProvider>
            </Loader>
        </div>
    );
}

Rewards.defaultProps = {
    rewards: [],
    rewardsStatus: "",
    rewardUpdateStatus: ""
};

Rewards.propTypes = {
    clearRewardUpdateStatus: PropTypes.func,
    getRewardsByYear: PropTypes.func,
    rewards: PropTypes.array,
    rewardsStatus: PropTypes.string,
    rewardUpdateStatus: PropTypes.string,
    toggleRewardActive: PropTypes.func
};

export default Rewards;