import { REWARDS, REWARDS_STATUS, REWARD_CREATE_STATUS, REWARD_IMAGES, REWARD_IMAGES_STATUS, REWARD_IMAGE_UPLOAD, REWARD_IMAGE_UPLOAD_STATUS,
    REWARD_UPDATE, REWARD_UPDATE_STATUS, REWARD_AWARD_KEYS, REWARD_AWARD_KEYS_STATUS, REWARD_AWARD_KEYS_UPLOAD, REWARD_AWARD_KEYS_UPLOAD_STATUS,
    REWARD_AWARD_KEYS_UPLOAD_DELETE_STATUS, REWARD_AWARD_KEYS_UPLOAD_DELETE } from "../actions-index";

export function rewards(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case REWARDS:
            return { ...state, data: payload };
        case REWARDS_STATUS:
            return { ...state, status: payload };
        case REWARD_UPDATE:
            // update the reward in the list
            return {
                ...state,
                data: state.data.map(reward => {
                    if(reward.rewardId === payload.rewardId) {
                        return payload;
                    } else {
                        return reward;
                    }
                })
            }
        default:
            return state;
    }
}

export function rewardUpdateStatus(state = "", action = {}) {
    return (action.type === REWARD_UPDATE_STATUS ? action.payload : state);
}

export function rewardImages(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case REWARD_IMAGES:
            return { ...state, data: payload };
        case REWARD_IMAGES_STATUS:
            return { ...state, status: payload };
        default:
            return state;
    }
}

export function rewardImageUpload(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case REWARD_IMAGE_UPLOAD:
            return { ...state, data: payload };
        case REWARD_IMAGE_UPLOAD_STATUS:
            return { ...state, status: payload };
        default:
            return state;
    }
}

export function rewardAwardKeys(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case REWARD_AWARD_KEYS:
            return { ...state, data: payload };
        case REWARD_AWARD_KEYS_STATUS:
            return { ...state, status: payload };
        default:
            return state;
    }
}

export function rewardAwardKeysUpload(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case REWARD_AWARD_KEYS_UPLOAD_DELETE:
        case REWARD_AWARD_KEYS_UPLOAD:
            return { ...state, data: payload };
        case REWARD_AWARD_KEYS_UPLOAD_DELETE_STATUS:
            return { ...state, status: 'Deleted' };
        case REWARD_AWARD_KEYS_UPLOAD_STATUS:
            return { ...state, status: payload };
        default:
            return state;
    }
}

export function rewardCreateStatus(state = "", action = {}) {
    return (action.type === REWARD_CREATE_STATUS ? action.payload : state);
}