import React, { Fragment, useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Loader from "react-loader";
import Select from "react-select";
import { Alert, Button, Col, Media, Row } from "reactstrap";
import PropTypes from "prop-types";
import { Icon } from "osu-react-components";
import moment from "moment";
import { keys } from "lodash";
import ExportToCSVButton from "../../Common/components/ExportToCSVButton";
import ShippingAddress from "../../Common/components/ShippingAddress";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS, ORDER_STATUS, ORDER_STATUS_ALL, ORDER_STATUS_PENDING } from "../../util/constants";
import { buildLabelValuePair } from "../../util/util";
import "../styles/List.css";

const DATE_DISPLAY_FORMAT = "MM/DD/YYYY";
const EXPORT_FILE_NAME = "Orders.csv";

function Orders(props) {
    const { clearOrderAddressUpdateStatus, clearOrderStatusUpdateStatus, fulfillOrder, getOrders, orders, ordersStatus,
        orderAddressUpdateStatus, orderStatusUpdateStatus, updateOrderAddress } = props;
    const alertsEl = useRef(null);
    const orderStatusSelectEl = useRef(null);
    const [orderStatusSelectOptions] = useState(
        keys(ORDER_STATUS).map(key => {
            return buildLabelValuePair(key, ORDER_STATUS[key]);
        })
    );
    const [orderStatusSelectedOption, setOrderStatusSelectedOption] = useState(
        orderStatusSelectOptions.find(option => option.value === ORDER_STATUS_PENDING)
    );
    const [orderTableSelectedRows, setOrderTableSelectedRows] = useState([]);
    const [isEditingShippingAddress, setIsEditingShippingAddress] = useState(false);

    // on mount
    useEffect(() => {
        const orderStatusSelect = document.getElementById("orderStatusSelect");
        if(orderStatusSelect && !orderStatusSelect.getAttribute("aria-describedby")) {
            orderStatusSelect.setAttribute("aria-describedby", "orderStatusSelectDesc"); // necessary because react-select does not support aria-describedby
        }
    }, []);

    // on order status select change
    // (triggered initially by the selected option default state)
    useEffect(() => {
        let status = orderStatusSelectedOption.value;
        if(status === ORDER_STATUS_ALL) status = null;
        getOrders(status);
    }, [getOrders, orderStatusSelectedOption]);

    // on order address update change
    useEffect(() => {
        if(orderAddressUpdateStatus === ACTION_STATUS_ERROR) alertsEl.current.focus();
        if(orderAddressUpdateStatus === ACTION_STATUS_SUCCESS) {
            setIsEditingShippingAddress(false);
            clearOrderAddressUpdateStatus();
        }
    }, [clearOrderAddressUpdateStatus, orderAddressUpdateStatus]);

    // on order status update change
    useEffect(() => {
        if([ACTION_STATUS_ERROR, ACTION_STATUS_SUCCESS].includes(orderStatusUpdateStatus)) alertsEl.current.focus();
    }, [orderStatusUpdateStatus]);

    // on unmount
    useEffect(() => {
        return () => {
            clearOrderAddressUpdateStatus();
            clearOrderStatusUpdateStatus();
        };
    }, [clearOrderAddressUpdateStatus, clearOrderStatusUpdateStatus]);

    // table properties
    const orderStatusAllSelected = (orderStatusSelectedOption.value === ORDER_STATUS_ALL);
    const orderStatusPendingSelected = (orderStatusSelectedOption.value === ORDER_STATUS_PENDING);
    const buildShippingAddressString = (shippingAddress) => {
        let shippingAddressFields = [shippingAddress.addressLine1];
        if (shippingAddress.addressLine2) {
            shippingAddressFields.push(shippingAddress.addressLine2);
        }
        if (shippingAddress.addressLine3) {
            shippingAddressFields.push(shippingAddress.addressLine3);
        }
        shippingAddressFields.push(shippingAddress.city);
        shippingAddressFields.push(shippingAddress.state);
        shippingAddressFields.push(shippingAddress.zip);
        if (shippingAddress.additionalInstructions) {
            shippingAddressFields.push(shippingAddress.additionalInstructions);
        }
        return shippingAddressFields.join(', ');
    }
    const emptyTableCellHandler = (cell) => (cell ? cell : "");
    const tableColumns = [
        { text: "Order Id", dataField: "id", sort: false, hidden: true, csvExport: false },
        { text: "Order Date", dataField: "redemptionDate", sort: true, headerStyle: { width: "5.4rem" },
            attrs: () => ({ "data-header": "Order Date" }),
            formatter: (cell) => { return moment(cell).format(DATE_DISPLAY_FORMAT); },
            csvFormatter: (cell) => { return moment(cell).format(DATE_DISPLAY_FORMAT); }
        },
        { text: "Order Status", dataField: "redemptionStatus", sort: true, hidden: !orderStatusAllSelected,
            attrs: () => ({ "data-header": "Order Status" }),
            csvExport: orderStatusAllSelected,
            formatter: (cell) => {
                let orderStatusOption = orderStatusSelectOptions.find(option => (option.value === cell));
                return orderStatusOption ? orderStatusOption.label : cell;
            },
            csvFormatter: (cell) => {
                let orderStatusOption = orderStatusSelectOptions.find(option => (option.value === cell));
                return orderStatusOption ? orderStatusOption.label : cell;
            }
        },
        { text: "EmplId", dataField: "emplId", hidden: true, csvExport: true },
        { text: "First Name", dataField: "firstName", sort: true, attrs: () => ({ "data-header": "First Name" }) },
        { text: "Last Name", dataField: "lastName", sort: true, attrs: () => ({ "data-header": "Last Name" }) },
        { text: "name.#", dataField: "username", hidden: true, csvExport: true, csvFormatter: (cell) => emptyTableCellHandler(cell) },
        { text: "Year", dataField: "rewardYear", sort: true, attrs: () => ({ "data-header": "Year" }) },
        { text: "Level", dataField: "rewardLevel", sort: true, attrs: () => ({ "data-header": "Level" }) },
        { text: "Image", dataField: "rewardImageUrl", headerStyle: { textAlign: "center" }, csvExport: false,
            attrs: () => ({ "data-header": "Image" }),
            formatter: (cell) => { return cell === null ? null : <Media object src={encodeURI(cell)} alt="Reward Image" className="reward-image" />; },
            csvFormatter: (cell) => { return cell === null ? "" : cell.substring(cell.lastIndexOf("/") + 1); }
        },
        { text: "Category", dataField: "category", csvExport:true, sort: true, csvFormatter: (cell) => emptyTableCellHandler(cell),
            attrs: () => ({ "data-header": "Category" }) },
        { text: "Title", dataField: "rewardTitle", sort: true, headerStyle:{ width: '175px' }, csvExport:true, attrs: () => ({ "data-header": "Title" }) },
        { text: "Type", dataField: "rewardType", sort: true, csvExport: true, csvFormatter: (cell) => emptyTableCellHandler(cell),
            attrs: () => ({ "data-header": "Type" }) },
        { text: "SKU", dataField: "SKU", sort: false, hidden: true, csvExport: true, headerStyle:{ width: '175px' }, attrs: () => ({ "data-header": "SKU" }), 
            csvFormatter: (cell) => emptyTableCellHandler(cell) },
        { text: "Shipping Address", dataField: "shippingAddress", hidden: true, csvExport: true, csvFormatter: (cell) => { return buildShippingAddressString(cell); } },
        { text: "Phone Number", dataField: "shippingAddress.phone", hidden: true, csvExport: true, csvFormatter: (cell) => emptyTableCellHandler(cell) },
        { text: "Med Center Employee", dataField: "shippingAddress.medCenterEmployee", hidden: true, csvExport: true, csvFormatter: (cell) => emptyTableCellHandler(cell) },
        { text: "Building Name", dataField: "shippingAddress.buildingName", hidden: true, csvExport: true, csvFormatter: (cell) => emptyTableCellHandler(cell) },
        { text: "Building Number", dataField: "shippingAddress.buildingNumber", hidden: true, csvExport: true, csvFormatter: (cell) => emptyTableCellHandler(cell) },
        { text: "Floor Number", dataField: "shippingAddress.floorNumber", hidden: true, csvExport: true, csvFormatter: (cell) => emptyTableCellHandler(cell) },
        { text: "Supervisor", dataField: "shippingAddress.supervisor", hidden: true, csvExport: true, csvFormatter: (cell) => emptyTableCellHandler(cell) }
    ];
    const tableExportToCSV = {
        fileName: `${orderStatusSelectedOption.value === ORDER_STATUS_ALL ? "" : (orderStatusSelectedOption.label + " ")}${EXPORT_FILE_NAME}` 
    };
    const tableDefaultSort = [{ dataField: "redemptionDate", order: "desc" }];
    const onTableChange = (type, { sortField, sortOrder, data }) => {
        // handle sort manually so that the data is sorted when exported
        if(type === "sort") {
            const compare = (a, b) => {
                if (a === b) {
                    return 0;
                }
                else if (a == null) {
                    return 1;
                }
                else if (b == null) {
                    return -1;
                }
                else { 
                    return a > b ? 1 : -1;
                }
            };
            data.sort((a, b) => {
                return sortOrder === "asc" ? compare(a[sortField], b[sortField]) : compare(b[sortField], a[sortField]);
            });
        }
    };
    const tableSelectRow = {
        mode: "radio",
        selected: orderTableSelectedRows,
        onSelect: (row) => {
            setOrderTableSelectedRows([row.id]);
            setIsEditingShippingAddress(false);
        },
        selectionRenderer: ({ mode, checked, disabled, rowIndex }) => {
            const name = `selectOrderRow${rowIndex}`;
            const desc = `selectOrderRow${rowIndex}Desc`;
            return (
                <div>
                    <label htmlFor={name} className="sr-only">Select Order</label> 
                    <input id={name} name={name} type={mode} checked={checked} disabled={disabled} aria-describedby={desc} onChange={()=>{}} />
                    <p id={desc} className="sr-only">Selects the order row and expands a sub-row with additional details about the order.</p>
                </div>
            );
        }
    }
    const tableExpandRow = {
        expanded: orderTableSelectedRows,
        renderer: (row) => {
            return (
                <Row className="order-table-expand-row">
                    <Col md={8}>
                        <ShippingAddress data={row.shippingAddress}
                            editable={orderStatusPendingSelected}
                            isEditing={isEditingShippingAddress}
                            label="Shipping Information"
                            onEdit={() => { setIsEditingShippingAddress(true); }}
                            onEditCancel={() => { setIsEditingShippingAddress(false); }}
                            onEditSubmit={onShippingAddressEditSubmit}
                            isSubmitting={orderAddressUpdateStatus === ACTION_STATUS_LOADING}
                            testId="order-shipping-information" />
                    </Col>
                </Row>
            );
        }
    };
    const tableNoDataIndication = () => {
        if(ordersStatus === ACTION_STATUS_ERROR) {
            return (
                <span className="osu-red">
                    <Icon type="exclamation-triangle" color="red" /> Orders can not be retrieved at this time.
                </span>
            );
        } else {
            return "There are currently no orders.";
        }
    };
    const hideTableButtons = (orders.length === 0 || ordersStatus === ACTION_STATUS_ERROR);

    const onOrderFulfillment = () => {
        if(orderStatusSelectedOption.value === ORDER_STATUS_PENDING && orderTableSelectedRows.length !== 0
            && orderStatusUpdateStatus !== ACTION_STATUS_LOADING) {
            const orderId = orderTableSelectedRows[0];
            fulfillOrder(orderId);
        }
    };

    const onShippingAddressEditSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const shippingAddress = {};
        for(const lvp of formData.entries()) {
            shippingAddress[lvp[0]] = lvp[1];
        }
        updateOrderAddress(orderTableSelectedRows[0], shippingAddress);
    }

    return (
        <div>
            <div data-testid="alerts" ref={alertsEl} tabIndex="-1" className="outline-none">
                <Alert data-testid="order-update-status-success" color="success" isOpen={orderStatusUpdateStatus === ACTION_STATUS_SUCCESS} toggle={clearOrderStatusUpdateStatus}>
                    <b>Order Update</b>
                    <p>The order has been successfully fulfilled.</p>
                </Alert>
                <Alert data-testid="order-update-status-error" color="danger" isOpen={orderStatusUpdateStatus === ACTION_STATUS_ERROR} toggle={clearOrderStatusUpdateStatus}>
                    <b>Order Update</b>
                    <p>An error occurred while fulfilling the order. Please retry to see if that resolves the issue.</p>
                </Alert>
                <Alert data-testid="order-update-address-error" color="danger" isOpen={orderAddressUpdateStatus === ACTION_STATUS_ERROR} toggle={clearOrderAddressUpdateStatus}>
                    <b>Order Update</b>
                    <p>An error occurred while updating the shipping information. Please retry to see if that resolves the issue.</p>
                </Alert>
            </div>
            <h2 className="d-inline-block" data-testid="header">Orders</h2>
            <div className="float-right">
                <label htmlFor="orderStatusSelect" className="d-inline-block">Status</label>:&nbsp;
                <Select id="orderStatusSelect" name="orderStatusSelect" className="display-inline-block order-status-select"
                    ref={orderStatusSelectEl} options={orderStatusSelectOptions} value={orderStatusSelectedOption}
                    onChange={option => setOrderStatusSelectedOption(option)} />
                <p id="orderStatusSelectDesc" className="sr-only">Changing the status selection updates the table to display orders with the selected status.</p>
            </div>
            <Loader loaded={ordersStatus !== ACTION_STATUS_LOADING} color="#666666">
                <ToolkitProvider bootstrap4 keyField="id" columns={tableColumns} data={orders} exportCSV={tableExportToCSV}>
                    {
                        props => (
                            <div>
                                <div>
                                    <BootstrapTable id="ordersTable" {...props.baseProps} bordered={false} remote={{ sort: true }}
                                        headerClasses="table-header" defaultSorted={tableDefaultSort} onTableChange={onTableChange}
                                        selectRow={tableSelectRow} expandRow={tableExpandRow} noDataIndication={tableNoDataIndication} />
                                    <hr />
                                </div>
                                <div className={`button-bar${hideTableButtons ? " d-none" : ""}`}>
                                    <ExportToCSVButton {...props.csvProps} label="Export Orders" ariaDescribedby="exportToCSVButtonDesc" />
                                    <p id="exportToCSVButtonDesc" className="sr-only">Exports the orders from the table to a CSV file.</p>
                                    <Button className={`order-fulfillment-btn osu-red-btn ${orderStatusPendingSelected ? "d-inline-block" : "d-none"}`}
                                        aria-describedby="orderFulfillmentButtonDesc"
                                        disabled={!orderStatusPendingSelected || orderTableSelectedRows.length === 0 || orderStatusUpdateStatus === ACTION_STATUS_LOADING}
                                        onClick={onOrderFulfillment}>
                                        Fulfill Order
                                    </Button>
                                    {orderStatusUpdateStatus === ACTION_STATUS_LOADING &&
                                        <Fragment>
                                            <div data-testid="order-status-update-processing" className="d-inline-block"><Icon type="spinner fa-spin" /> Processing</div>
                                            <div aria-live="polite" className="sr-only" data-testid="order-status-update-processing-aria">Order fulfillment is processing.</div>
                                        </Fragment>
                                    }
                                    <p id="orderFulfillmentButtonDesc" className="sr-only">
                                        Fulfills the selected order.  Disabled if no order is selected.
                                    </p>
                                </div>
                            </div>
                        )
                    }
                </ToolkitProvider>
            </Loader>
        </div>
    );
}

Orders.defaultProps = {
    orders: [],
    ordersStatus: "",
    orderAddressUpdateStatus: "",
    orderStatusUpdateStatus: ""
};

Orders.propTypes = {
    clearOrderAddressUpdateStatus: PropTypes.func,
    clearOrderStatusUpdateStatus: PropTypes.func,
    fulfillOrder: PropTypes.func,
    getOrders: PropTypes.func,
    orders: PropTypes.array,
    ordersStatus: PropTypes.string,
    orderAddressUpdateStatus: PropTypes.string,
    orderStatusUpdateStatus: PropTypes.string,
    updateOrderAddress: PropTypes.func
};

export default Orders;