import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Alert, Button, Col, Form, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label, Media, Row } from "reactstrap";
import { Icon } from "osu-react-components";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS } from "../../util/constants";
import { buildAccessibleFormFeedback, buildLabelValuePair } from "../../util/util";
import "../styles/Create.css";
import { capitalize } from "lodash";

const TYPES = process.env.REACT_APP_REWARD_TYPES ? process.env.REACT_APP_REWARD_TYPES.split(",") : []
const TYPES_OPTIONS = TYPES.map((t) => ({value: t, label: capitalize(t)}))

const ACTION_STATUS_EXISTS = "exists";
const FORM_ID = "rewardForm";
const FILE_MAX_SIZE = 2621440; // 2.5MB
const FILE_REGEX = new RegExp(/^[a-z0-9!-_.*'()]+$/i);
const IMAGES_OPTIONS_GROUP_LABEL = "Images";
const CATEGORIES = "categories";
const UPLOAD_IMAGE_OPTION_LABEL = "Upload New Image";
const UPLOAD_IMAGE_OPTION_VALUE = "UPLOAD_NEW_IMAGE";

function CreateReward(props) {
    const { clearRewardCreateStatus, clearRewardImageUpload, createReward, getRewardImages, rewardCreateStatus,
        rewardImages, rewardImagesStatus, rewardImageUpload, rewardImageUploadStatus, uploadRewardImage, 
        rewardAwardKeysUploadStatus, uploadRewardAwardKeys, clearRewardAwardKeysUpload, rewardAwardKeysUpload
     } = props;
    const alertsEl = useRef(null);
    const imageUrlSelectEl = useRef(null);
    const [inputInvalid, setInputInvalid] = useState({});
    const [imageUrlSelectOptions, setImageUrlSelectOptions] = useState(
        [
            buildLabelValuePair("", "Select Image..."),
            buildLabelValuePair(UPLOAD_IMAGE_OPTION_VALUE, UPLOAD_IMAGE_OPTION_LABEL),
            { label: IMAGES_OPTIONS_GROUP_LABEL, options: [] }
        ]
    )
    const [imageUrlSelectedOption, setImageUrlSelectedOption] = useState(imageUrlSelectOptions[0]);
    const [rewardTypeSelectedOption, setRewardTypeSelectedOption] = useState(TYPES_OPTIONS[0]);
    const [imageFile, setImageFile] = useState(null);
    const [imageFileFeedback, setImageFileFeedback] = useState(null);
    const [awardKeysFile, setAwardKeysFile] = useState(null);
    const [awardKeysFileFeedback, setAwardKeysFileFeedback] = useState(null);
    const [categories, setCategories] = useState([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    // on mount
    useEffect(() => {
        const imageUrlSelect = document.getElementById("imageUrl");
        if(imageUrlSelect && !imageUrlSelect.getAttribute("aria-describedby")) {
            imageUrlSelect.setAttribute("aria-describedby", "imageUrlDesc"); // necessary because react-select does not support aria-describedby
        }
    }, []);

    // retrieve reward images
    useEffect(() => {
        if(rewardImagesStatus === "") getRewardImages();
    }, [rewardImagesStatus, getRewardImages])

    // when reward images are retrieved
    useEffect(() => {
        if(rewardImagesStatus === ACTION_STATUS_SUCCESS) {
            const selectOptions = [...imageUrlSelectOptions];
            const imagesOptionsGroup = selectOptions.find(option => (option.label === IMAGES_OPTIONS_GROUP_LABEL));
            if(rewardImages.length > 0 && imagesOptionsGroup.options.length === 0) {
                rewardImages.forEach(imageUrl => {
                    imagesOptionsGroup.options.push(buildImageUrlSelectOption(imageUrl));
                });
                setImageUrlSelectOptions(selectOptions);
            }
        }
    }, [imageUrlSelectOptions, rewardImages, rewardImagesStatus, setImageUrlSelectOptions]);

    // on reward image upload status change
    useEffect(() => {
        if(rewardImageUploadStatus === ACTION_STATUS_SUCCESS) {
            if(rewardImageUpload) {
                const imagesOptionsGroup = imageUrlSelectOptions.find(option => (option.label === IMAGES_OPTIONS_GROUP_LABEL));
                imagesOptionsGroup.options.push(buildImageUrlSelectOption(rewardImageUpload)); // add the uploaded image to the images option group of the imageUrl select
                setImageUrlSelectedOption(imagesOptionsGroup.options[imagesOptionsGroup.options.length - 1]); // set the uploaded image as the imageUrl selected option
            }
            setImageFile(null); // clear the selected file
            clearRewardImageUpload(); // clear the upload
            imageUrlSelectEl.current.focus(); // set focus on the imageUrl select
        }
        if([ACTION_STATUS_ERROR, ACTION_STATUS_EXISTS].includes(rewardImageUploadStatus)) alertsEl.current.focus();
    }, [clearRewardImageUpload, imageUrlSelectOptions, rewardImageUpload, rewardImageUploadStatus]);

    // on form field change
    useEffect(() => {
        const form = document.getElementById(FORM_ID);
        const isFormValid = form.checkValidity();
        const isImageValid = !["", UPLOAD_IMAGE_OPTION_VALUE].includes(imageUrlSelectedOption.value);
        setIsSubmitDisabled(!(isFormValid && isImageValid));
    }, [inputInvalid, imageUrlSelectedOption]);

    // on reward create status change
    useEffect(() => {
        if([ACTION_STATUS_ERROR, ACTION_STATUS_SUCCESS].includes(rewardCreateStatus)) alertsEl.current.focus();
        if(rewardCreateStatus === ACTION_STATUS_SUCCESS) {
            // reset the form and state
            document.getElementById(FORM_ID).reset();
            setInputInvalid({});
            setRewardTypeSelectedOption(TYPES_OPTIONS[0])
            setImageUrlSelectedOption(imageUrlSelectOptions[0]);
            setImageFile(null);
            setImageFileFeedback(null);
            setAwardKeysFile(null);
            setAwardKeysFileFeedback(null);
            setCategories([]);
            setIsSubmitDisabled(true);
            clearRewardAwardKeysUpload();
        }
    }, [imageUrlSelectOptions, rewardCreateStatus, clearRewardAwardKeysUpload]);

    // on unmount
    useEffect(() => {
        return () => {
            clearRewardImageUpload();
            clearRewardAwardKeysUpload();
            clearRewardCreateStatus();
        };
    }, [clearRewardCreateStatus, clearRewardImageUpload, clearRewardAwardKeysUpload]);

    const buildImageUrlSelectOption = (imageUrl) => {
        const imageLabel = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
        return buildLabelValuePair(imageUrl, imageLabel);
    };

    const validateSelect = (selectName) => (option) => {
        const value = option.value;
        const optionValid = TYPES.some((t) => t === value);
        setInputInvalid({ ...inputInvalid, [selectName]: !optionValid });
        optionValid && setRewardTypeSelectedOption(option)
    };

    const validateInput = (event) => {
        const input = event.target;
        setInputInvalid({ ...inputInvalid, [input.name]: !input.checkValidity() });
    };

    const onImageFileSelection = (event) => {
        const imageFileInput = event.target;
        const imageFile = (imageFileInput.files.length > 0 ? imageFileInput.files[0] : null);
        let imageFileInputInvalid = false;
        let imageFileFeedback = null;
        if(imageFile) {
            if(!FILE_REGEX.test(imageFile.name)) {
                imageFileInputInvalid = true;
                imageFileFeedback = "The image name contains unsupported characters. Supported characters: A-Z a-z 0-9 ! - _ . * \\' ()";
            }
            if(imageFile.size > FILE_MAX_SIZE) {
                imageFileInputInvalid = true;
                imageFileFeedback = "The image size exceeds the 2.5MB limit.";
            }
        }
        setImageFile(imageFile);
        setInputInvalid({...inputInvalid, [imageFileInput.name]: imageFileInputInvalid})
        setImageFileFeedback(imageFileFeedback);
    };

    const onImageFileUpload = () => {
        if(imageFile && !inputInvalid.imageFile && rewardImageUploadStatus !== ACTION_STATUS_LOADING) {
            clearRewardImageUpload();
            uploadRewardImage(imageFile);
        }
    };

    const onAwardKeysFileSelection = (event) => {
        const awardKeysFileInput = event.target;
        const awardKeysFile = (awardKeysFileInput.files.length > 0 ? awardKeysFileInput.files[0] : null);
        let awardKeysFileInputInvalid = false;
        let awardKeysFileFeedback = null;

        if(awardKeysFile) {
            if(!FILE_REGEX.test(awardKeysFile.name)) {
                awardKeysFileInputInvalid = true;
                awardKeysFileFeedback = "The award keys file name contains unsupported characters. Supported characters: A-Z a-z 0-9 ! - _ . * \\' ()";
            }
        }
        setAwardKeysFile(awardKeysFile);
        setInputInvalid({...inputInvalid, [awardKeysFileInput.name]: awardKeysFileInputInvalid})
        setAwardKeysFileFeedback(awardKeysFileFeedback);

    }

    const clearAwardKeysInfo = (rewardAwardKeysUpload) => () => {
        clearRewardAwardKeysUpload(rewardAwardKeysUpload);
        setAwardKeysFile(null);
    }

    const onAwardKeysFileUpload = () => {
        if(awardKeysFile && rewardAwardKeysUploadStatus !== ACTION_STATUS_LOADING) {
            clearRewardAwardKeysUpload();
            uploadRewardAwardKeys(awardKeysFile);
        }
    };

    const onCategoryAdd = () => {
        setCategories([...categories, ""]);
    };

    const onCategoryChange = (index, value) => {
        const updatedCategories = [...categories];
        updatedCategories[index] = value;
        setCategories(updatedCategories);
    };

    const onCategoryRemove = (index) => {
        let updatedCategories = [...categories];
        updatedCategories = updatedCategories.slice(0,index).concat(updatedCategories.slice(index + 1, updatedCategories.length));
        setCategories(updatedCategories);
    };

    const onFormSubmit = (event) => {
        event.preventDefault();
        if(!isSubmitDisabled && rewardCreateStatus !== ACTION_STATUS_LOADING) {
            const form = event.target;
            const formData = new FormData(form);
            const reward = {};
            for(let lvp of formData.entries()) {
                let property = lvp[0];
                let value = lvp[1];
                if(reward[property] && Array.isArray(reward[property])){
                    reward[property] = [...reward[property], value];
                } else if(reward[property]) {
                    reward[property] = [reward[property], value];
                } else {
                    reward[property] = value;
                }
            }
            delete reward.imageFile; // do not include the image file, only the image url
            delete reward.awardKeysFile; // do not include the csv file, only the image url
            if(reward[CATEGORIES] && !Array.isArray(reward[CATEGORIES])) { //insuring that categories will be sent to API correctly
                    reward[CATEGORIES] = [reward[CATEGORIES]];
            }
            createReward(reward);
        }
    };

    const clearInputValue = (event) => {
        return event.target.value = "";
    }

    return (
        <div>
            <div data-testid="alerts" ref={alertsEl} tabIndex="-1" className="outline-none">
                <Alert data-testid="reward-image-upload-error" color="danger" isOpen={rewardImageUploadStatus === ACTION_STATUS_ERROR} toggle={clearRewardImageUpload}>
                    <b>Reward Image Upload</b>
                    <p>An error occurred while uploading the reward image. Please retry to see if that resolves the issue.</p>
                </Alert>
                <Alert data-testid="reward-award-keys-upload-error" color="danger" isOpen={rewardAwardKeysUploadStatus === ACTION_STATUS_ERROR} toggle={clearRewardAwardKeysUpload}>
                    <b>Reward Award Keys Upload</b>
                    <p>An error occurred while uploading the reward award keys. Please retry to see if that resolves the issue.</p>
                </Alert>
                <Alert data-testid="reward-image-exists-error" color="danger" isOpen={rewardImageUploadStatus === ACTION_STATUS_EXISTS} toggle={clearRewardImageUpload}>
                    <b>Reward Image Upload</b>
                    <p>An image with this name already exists. Refresh the page if the image is not listed in the dropdown.</p>
                </Alert>
                <Alert data-testid="reward-creation-success" color="success" isOpen={rewardCreateStatus === ACTION_STATUS_SUCCESS} toggle={clearRewardCreateStatus}>
                    <b>Reward Create</b>
                    <p>The reward was successfully created and can be viewed on the Rewards page.</p>
                </Alert>
                <Alert data-testid="reward-creation-error" color="danger" isOpen={rewardCreateStatus === ACTION_STATUS_ERROR} toggle={clearRewardCreateStatus}>
                    <b>Reward Create</b>
                    <p>An error occurred while creating the reward. Please retry to see if that resolves the issue.</p>
                </Alert>
            </div>
            <h2 data-testid="header">Create Reward</h2>
            <Form id={FORM_ID} data-testid="create-reward-form" noValidate onSubmit={onFormSubmit}>
                <FormGroup tag="fieldset" className="mb-0">
                    <legend className="sr-only">Reward</legend>
                    <Row form>
                        <Col md={1} style={{ minWidth: "5.7rem" }}>
                            <FormGroup>
                                <Label for="rewardYear" className="required">Year</Label>
                                <Input type="text" id="rewardYear" name="rewardYear" required pattern="\d{4}" maxLength="4"
                                    invalid={inputInvalid.rewardYear} onChange={validateInput} className="reward-year-input" />
                                {buildAccessibleFormFeedback("Year is invalid.")}
                            </FormGroup>
                        </Col>
                        <Col md={11}>
                            <FormGroup>
                                <Label for="rewardLevel" className="required">Level</Label>
                                <Input type="text" id="rewardLevel" name="rewardLevel" required pattern="^((?!(0))[0-9]{1,2})" maxLength="2"
                                    invalid={inputInvalid.rewardLevel} onChange={validateInput} className="reward-level-input" />
                                {buildAccessibleFormFeedback("Level is invalid.")}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="SKU">SKU</Label>
                                <Input type="text" id="SKU" name="SKU" pattern="\d{5,20}" maxLength="20" minLength="5"
                                    invalid={inputInvalid.SKU} onChange={validateInput} className="sku-input" />
                                {buildAccessibleFormFeedback("SKU is invalid.")}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={12} style={{ minWidth: "5.7rem" }}>
                            <FormGroup>
                                <Label for="rewardType" className="required">Type</Label>
                                <Select id="rewardType" name="rewardType" inputId="rewardTypeInput" options={TYPES_OPTIONS} value={rewardTypeSelectedOption}
                                    onChange={validateSelect("rewardType")}  />
                                {inputInvalid.rewardType ? buildAccessibleFormFeedback("Type is invalid.") : <span></span>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className="reward-image-form-group">
                        <Label for="imageUrl" className="required">Image</Label>
                        <Select id="imageUrl" name="imageUrl" inputId="imageUrlInput" ref={imageUrlSelectEl} options={imageUrlSelectOptions} value={imageUrlSelectedOption}
                            onChange={(option) => setImageUrlSelectedOption(option)} />
                        <p id="imageUrlDesc" className="sr-only">
                            {`Associates an image with the reward. Selecting '${UPLOAD_IMAGE_OPTION_LABEL}' displays fields for uploading an image.`}
                        </p>
                        {!["", UPLOAD_IMAGE_OPTION_VALUE].includes(imageUrlSelectedOption.value) && 
                            <div data-testid="image-display" className="mt-2">
                                <Media object src={encodeURI(imageUrlSelectedOption.value)} alt={`Reward image '${imageUrlSelectedOption.label}'`} className="reward-image" />
                            </div>
                        }
                    </FormGroup>
                    <FormGroup className="reward-image-upload-form-group" hidden={imageUrlSelectedOption.value !== UPLOAD_IMAGE_OPTION_VALUE}>
                        <Label for="imageFile" className="sr-only">Image File</Label>
                        <FormText color="muted">Upload an image to associate with the reward. JPG, JPEG, and PNG files under 2.5MB are supported.</FormText>
                        <div className="custom-file reward-image-file">
                            <Input type="file" id="imageFile" name="imageFile" accept="image/jpg, image/jpeg, image/png" className="custom-file-input"
                                required={imageUrlSelectedOption === UPLOAD_IMAGE_OPTION_VALUE} invalid={inputInvalid.imageFile} onChange={onImageFileSelection} />
                            <Label className="custom-file-label">{(imageFile ? imageFile.name : "Choose File")}</Label>
                            {imageFile && 
                                <div data-testid="image-file-display" className="mt-2">
                                    <Media object src={encodeURI(URL.createObjectURL(imageFile))} alt={`Reward image '${imageFile.name}'`} className="reward-image" />
                                </div>
                            }
                            {imageFileFeedback && buildAccessibleFormFeedback(imageFileFeedback)}
                            <Button className="d-inline-block mt-2 mr-1 osu-red-btn" disabled={(!imageFile || inputInvalid.imageFile || rewardImageUploadStatus === ACTION_STATUS_LOADING)}
                                onClick={onImageFileUpload}>Upload</Button>
                            {rewardImageUploadStatus === ACTION_STATUS_LOADING &&
                                <Fragment>
                                    <div data-testid="reward-image-upload-processing" className="d-inline-block"><Icon type="spinner fa-spin" /> Processing</div>
                                    <div aria-live="polite" className="sr-only" data-testid="reward-image-upload-processing-aria">Reward image upload is processing.</div>
                                </Fragment>
                            }
                            {rewardImageUploadStatus === ACTION_STATUS_SUCCESS &&
                                <div aria-live="polite" className="sr-only" data-testid="reward-image-upload-success-aria">Reward image upload is complete.</div>
                            }
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label for="title" className="required">Title</Label>
                        <Input type="text" id="title" name="title" required invalid={inputInvalid.title} onChange={validateInput} />
                        {buildAccessibleFormFeedback("Title is required.")}
                    </FormGroup>
                    <FormGroup className="reward-award-keys-upload-form-group" hidden={rewardAwardKeysUpload}>
                        <Label for="awardKeyFile">Award Keys Upload</Label>
                        <div className="custom-file reward-award-keys-file" >
                            <Input type="file" id="awardKeysFile" name="awardKeysFile" accept=".csv, .CSV" className="custom-file-input"
                                invalid={inputInvalid.awardKeysFile} onClick={clearInputValue} onChange={onAwardKeysFileSelection} />
                                <Label className="custom-file-label">{(awardKeysFile ? awardKeysFile.name : "Choose File")}</Label>
                            {awardKeysFileFeedback && buildAccessibleFormFeedback(awardKeysFileFeedback)}
                            <Button className="d-inline-block mt-2 mr-1 mb-4 osu-red-btn" disabled={(!awardKeysFile ||  rewardAwardKeysUploadStatus === ACTION_STATUS_LOADING)}
                                onClick={onAwardKeysFileUpload}>Upload</Button>
                            {rewardImageUploadStatus === ACTION_STATUS_LOADING &&
                                <Fragment>
                                    <div data-testid="reward-award-upload-processing" className="d-inline-block"><Icon type="spinner fa-spin" /> Processing</div>
                                    <div aria-live="polite" className="sr-only" data-testid="reward-award keys-upload-processing-aria">Reward award file upload is processing.</div>
                                </Fragment>
                            }
                            {rewardAwardKeysUploadStatus === ACTION_STATUS_SUCCESS &&
                                <div aria-live="polite" className="sr-only" data-testid="reward-image-upload-success-aria">Reward award file upload is complete.</div>
                            }
                        </div>
                    </FormGroup>
                    <FormGroup className="reward-award-keys-form-group" >
                        <Label for="awardKeysPath">Award Keys</Label>
                        <Input id="awardKeysPath" name="awardKeysPath" defaultValue={rewardAwardKeysUpload || ""} hidden/>
                        <p className="ml-2" hidden={rewardAwardKeysUpload}>No Award Key CSV File Available</p>
                        <p hidden={!rewardAwardKeysUpload}>
                            <Button className="reward-award-btn" onClick={clearAwardKeysInfo(rewardAwardKeysUpload)}>{awardKeysFile ? awardKeysFile.name : "Award Keys CSV"} <b>X</b></Button>
                        </p>
                    </FormGroup>
                    <FormGroup tag="fieldset">
                        <legend className="mb-0 reward-categories-label">Categories</legend>
                        <FormText color="muted" className="mt-0 mb-2">(sizes, colors, etc.)</FormText>
                        {categories.map((category, index) => {
                            const number = (index + 1);
                            return (
                                <InputGroup key={`category${number}`} className="mb-2">
                                    <Input name="categories" value={category} placeholder={`Category #${number}`}
                                        onChange={e => onCategoryChange(index, e.target.value)} className="reward-category-input" />
                                    <InputGroupAddon addonType="append">
                                        <Button aria-label={`Remove Category #${number}`} onClick={() => onCategoryRemove(index)}>
                                            <Icon type="times" color="white" />
                                        </Button>
                                    </InputGroupAddon>
                                </InputGroup>
                            );
                        })}
                        <Button onClick={onCategoryAdd} className="osu-red-btn">Add Category</Button>
                    </FormGroup>
                    <FormGroup>
                        <Label for="description">Description</Label>
                        <Input type="textarea" id="description" name="description" />
                    </FormGroup>
                </FormGroup>
                <Button type="submit" className="osu-red-btn reward-create-btn" disabled={isSubmitDisabled}>Submit</Button>
                {rewardCreateStatus === ACTION_STATUS_LOADING &&
                    <Fragment>
                        <div data-testid="rewards-creation-processing" className="d-inline-block ml-1"><Icon type="spinner fa-spin" /> Processing</div>
                        <div aria-live="polite" className="sr-only" data-testid="rewards-creation-processing-aria">Reward creation is processing.</div>
                    </Fragment>
                }
            </Form>
        </div>
    );
}

CreateReward.defaultProps = {
    rewardCreateStatus: "",
    rewardImages: [],
    rewardImagesStatus: "",
    rewardImageUpload: null,
    rewardImageUploadStatus: ""
};

CreateReward.propTypes = {
    clearRewardCreateStatus: PropTypes.func,
    clearRewardImageUpload: PropTypes.func,
    createReward: PropTypes.func,
    getRewardImages: PropTypes.func,
    rewardCreateStatus: PropTypes.string,
    rewardImages: PropTypes.array,
    rewardImagesStatus: PropTypes.string,
    rewardImageUpload: PropTypes.string,
    rewardImageUploadStatus: PropTypes.string,
    uploadRewardImage: PropTypes.func,
    uploadAwardKeysFile: PropTypes.func
};

export default CreateReward;