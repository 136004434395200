import { useEffect, useMemo, useState } from "react";

const useDarkMode = () => {
  const mediaQueryList = useMemo(
    () => window.matchMedia("(prefers-color-scheme: dark)"),
    []
  );
  const [matches, setMatches] = useState(mediaQueryList.matches);

  useEffect(() => {
    const listener = (event) => {
      setMatches(event.matches);
    };

    mediaQueryList.addEventListener("change", listener);

    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, [mediaQueryList]);

  useEffect(() => {
    const rootElement = document.getElementById('root')
    if(matches) {
      rootElement.classList.add('dark-mode-enabled')
    } else {
      rootElement.classList.remove('dark-mode-enabled')
    }
  }, [matches])

  return {
    darkMode: matches,
  };
};

export default useDarkMode;
