import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

function ExportToCSVButton(props) {
    const { ariaDescribedby, disabled, hidden, label, onExport } = props;
    const onButtonClick = () => {
        if(onExport) onExport();
    }
    return (
        <Button disabled={disabled} onClick={onButtonClick} aria-describedby={ariaDescribedby} 
            className={`osu-red-btn${hidden === true ? " d-none" : ""}`}>
            {label}
        </Button>
    );
}

ExportToCSVButton.defaultProps = {
    ariaDescribedby: null,
    disabled: false,
    hidden: false,
    label: "Export to CSV"
}

ExportToCSVButton.propTypes = {
    ariaDescribedby: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    label: PropTypes.string,
    onExport: PropTypes.func
}

export default ExportToCSVButton;