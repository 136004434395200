import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Orders from "../components/List";
import { getOrders, updateOrderAddress, updateOrderStatus, ORDER_ADDRESS_UPDATE_STATUS, ORDER_STATUS_UPDATE_STATUS } from "../../actions-index";
import { ORDER_STATUS_FULFILLED } from "../../util/constants";
import { buildAction } from "../../util/util";

const mapStateToProps = (state) => {
    const { data: orders = [], status: ordersStatus = "" } = state.orders;
    return {
        orders,
        ordersStatus,
        orderAddressUpdateStatus: state.orderAddressUpdateStatus,
        orderStatusUpdateStatus: state.orderStatusUpdateStatus
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearOrderAddressUpdateStatus: () => dispatch(buildAction(ORDER_ADDRESS_UPDATE_STATUS, "")),
        clearOrderStatusUpdateStatus: () => dispatch(buildAction(ORDER_STATUS_UPDATE_STATUS, "")),
        fulfillOrder: (id) => dispatch(updateOrderStatus(id, ORDER_STATUS_FULFILLED)),
        getOrders: (status) => dispatch(getOrders(status)),
        updateOrderAddress: (id, address) => dispatch(updateOrderAddress(id, address))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));