import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { Collapse, Navbar, NavbarBrand, NavbarToggler, Nav, NavItem, NavLink } from "reactstrap";
import "../styles/Header.css";

function Header(props) {
    const { isAuthorized } = props;
    const { pathname } = useLocation();
    const [isNavbarMenuOpen, setIsNavbarMenuOpen] = useState(false);
    const toggleNavbarMenuOpen = () => setIsNavbarMenuOpen(!isNavbarMenuOpen);
    return (
        <div className="header">
            <div className="header-row row">
                <Navbar expand="xl" light className="navbar-menu">
                    <NavbarToggler aria-label="Toggle Navbar Menu" onClick={toggleNavbarMenuOpen}/>
                    <Collapse isOpen={isNavbarMenuOpen} navbar>
                        <Nav navbar>
                            <NavbarBrand tag={Link} to="/" className="d-none d-lg-block">
                                <img src="/images/incentive-logo-transparent.svg" alt="Cybersecurity For You" width="150"/>
                            </NavbarBrand>
                            {isAuthorized === true &&
                                <Fragment>
                                    <NavItem>
                                        <NavLink tag={Link} to="/order/list" className={(pathname === "/order/list" ? "navbar-nav-active" : "")}>Orders</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/reward/list" className={(pathname === "/reward/list" ? "navbar-nav-active" : "")}>Rewards</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} to="/reward/create" className={(pathname === "/reward/create" ? "navbar-nav-active" : "")}>Create Reward</NavLink>
                                    </NavItem>
                                </Fragment>
                            }
                            <NavItem>
                                <NavLink href="https://cybersecurity.osu.edu" target="_blank" rel="noopener noreferrer" className="navbar-nav-c4u">cybersecurity.osu.edu</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        </div>
    );
}

Header.defaultProps = {
    isAuthorized: false
};

Header.propTypes = {
    isAuthorized: PropTypes.bool
};

export default Header;