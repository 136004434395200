import { API } from "aws-amplify";
import { REWARDS, REWARDS_STATUS, REWARD_CREATE_STATUS, REWARD_IMAGES, REWARD_IMAGES_STATUS,
    REWARD_IMAGE_UPLOAD, REWARD_IMAGE_UPLOAD_STATUS, REWARD_UPDATE, REWARD_UPDATE_STATUS, 
    REWARD_AWARD_KEYS_UPLOAD, REWARD_AWARD_KEYS_UPLOAD_STATUS, REWARD_AWARD_KEYS_STATUS, REWARD_AWARD_KEYS,
    REWARD_AWARD_KEYS_UPLOAD_DELETE_STATUS, REWARD_AWARD_KEYS_UPLOAD_DELETE } from "../actions-index";
import { handleUnauthenticated } from "../Authentication/util";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS } from "../util/constants";
import { buildAction, readFileAsBase64 } from "../util/util";

export function getRewardsByYear(year) {
    return dispatch => {
        dispatch(buildAction(REWARDS_STATUS, ACTION_STATUS_LOADING));
        API.get(process.env.REACT_APP_API_NAME, `/reward/year/${year}`)
        .then(response => {
            dispatch(buildAction(REWARDS, response.data));
            dispatch(buildAction(REWARDS_STATUS, ACTION_STATUS_SUCCESS));
        })
        .catch(error => {
            console.error("Get Rewards By Year Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(REWARDS_STATUS, ACTION_STATUS_ERROR));
            });
        });
    };
}

export function updateReward(rewardId, request) {
    return dispatch => {
        dispatch(buildAction(REWARD_UPDATE_STATUS, ACTION_STATUS_LOADING));
        API.patch(process.env.REACT_APP_API_NAME, `/reward/${rewardId}`, {body: request})
        .then((response) => {
            dispatch(buildAction(REWARD_UPDATE, response.data));
            dispatch(buildAction(REWARD_UPDATE_STATUS, ACTION_STATUS_SUCCESS));
        })
        .catch(error => {
            console.error("Update Reward Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(REWARD_UPDATE_STATUS, ACTION_STATUS_ERROR));
            });
        });
    };
}

export function getRewardImages() {
    return dispatch => {
        dispatch(buildAction(REWARD_IMAGES_STATUS, ACTION_STATUS_LOADING));
        API.get(process.env.REACT_APP_API_NAME, "/reward/images")
        .then(rewardImages => {
            dispatch(buildAction(REWARD_IMAGES, rewardImages.data));
            dispatch(buildAction(REWARD_IMAGES_STATUS, ACTION_STATUS_SUCCESS));
        })
        .catch(error => {
            console.error("Get Reward Images Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(REWARD_IMAGES_STATUS, ACTION_STATUS_ERROR));
            });
        });
    };
}

export function uploadRewardImage(rewardImage) {
    return async (dispatch) => {
        dispatch(buildAction(REWARD_IMAGE_UPLOAD_STATUS, ACTION_STATUS_LOADING));
        try {
            const data = await readFileAsBase64(rewardImage);
            const request = { data, name: rewardImage.name, size: rewardImage.size, type: rewardImage.type };
            const postImageResponse = await API.post(process.env.REACT_APP_API_NAME, "/reward/image", { body: request });
            dispatch(buildAction(REWARD_IMAGE_UPLOAD, postImageResponse.data));
            dispatch(buildAction(REWARD_IMAGE_UPLOAD_STATUS, ACTION_STATUS_SUCCESS));
        } catch(error) {
            console.error("Upload Reward Image Error: ", error);
            handleUnauthenticated(error, () => {
                if(error.response && error.response.status === 403) {
                    dispatch(buildAction(REWARD_IMAGE_UPLOAD_STATUS, "exists")); // an image already exists with this name
                } else {
                    dispatch(buildAction(REWARD_IMAGE_UPLOAD_STATUS, ACTION_STATUS_ERROR));
                }
            });
        }
    };
}

export function getRewardAwardKeys(fileName) {
    return dispatch => {
        dispatch(buildAction(REWARD_AWARD_KEYS_STATUS, ACTION_STATUS_LOADING));
        API.get(process.env.REACT_APP_API_NAME, `/reward/keys/${fileName}`)
        .then(rewardAwardKeys => {
            dispatch(buildAction(REWARD_AWARD_KEYS, rewardAwardKeys.data));
            dispatch(buildAction(REWARD_AWARD_KEYS_STATUS, ACTION_STATUS_SUCCESS));
        })
        .catch(error => {
            console.error("Get Reward Award Keys Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(REWARD_AWARD_KEYS_STATUS, ACTION_STATUS_ERROR));
            });
        });
    };
}

export function uploadRewardAwardKeys(rewardAwardKeys) {
    return async (dispatch) => {
        dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD_STATUS, ACTION_STATUS_LOADING));
        try {
            const data = await readFileAsBase64(rewardAwardKeys);
            const request = { data, name: rewardAwardKeys.name, size: rewardAwardKeys.size, type: rewardAwardKeys.type };
            const postAwardKeysResponse = await API.post(process.env.REACT_APP_API_NAME, "/reward/keys", { body: request });
            console.log("postAwardKeysResponse.data", postAwardKeysResponse.data);
            dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD, postAwardKeysResponse.data));
            dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD_STATUS, ACTION_STATUS_SUCCESS));
        } catch(error) {
            console.error("Upload Reward Award Keys Error: ", error);
            handleUnauthenticated(error, () => {
                if(error.response && error.response.status === 403) {
                    dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD_STATUS, "exists")); // an award keys file already exists with this name
                } else {
                    dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD_STATUS, ACTION_STATUS_ERROR));
                }
            });
        }
    };
}

export function deleteRewardAwardKeysUpload(rewardAwardKeysPath) {
    return async dispatch => {
        dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD_DELETE_STATUS, ACTION_STATUS_LOADING));
        try {
            const request = { rewardAwardKeysPath };
            const deleteAwardKeysResponse = await API.del(process.env.REACT_APP_API_NAME, "/del/reward/keys", { body: request });
            console.log("deleteAwardKeysResponse.data", deleteAwardKeysResponse.data);
            dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD_DELETE, null));
            dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD_DELETE_STATUS, ACTION_STATUS_SUCCESS));
        } catch (error) {
            console.error("Upload Reward Award Keys Error: ", error);
            handleUnauthenticated(error, () => {
                if(error.response && error.response.status === 403) {
                    dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD_DELETE_STATUS, "NoSuchKey")); // an award keys file already exists with this name
                } else {
                    dispatch(buildAction(REWARD_AWARD_KEYS_UPLOAD_DELETE_STATUS, ACTION_STATUS_ERROR));
                }
            });
        }

    }
}

export function createReward(reward) {
    return dispatch => {
        dispatch(buildAction(REWARD_CREATE_STATUS, ACTION_STATUS_LOADING));
        API.post(process.env.REACT_APP_API_NAME, "/reward", { body: { reward } })
        .then(() => {
            dispatch(buildAction(REWARDS_STATUS, "")); // force rewards to update
            dispatch(buildAction(REWARD_CREATE_STATUS, ACTION_STATUS_SUCCESS));
        })
        .catch(error => {
            console.error("Create Reward Error: ", error);
            handleUnauthenticated(error, () => {
                dispatch(buildAction(REWARD_CREATE_STATUS, ACTION_STATUS_ERROR));
            });
        });
    };
}