import React from "react";
import { OSUButton } from "osu-react-components";
import { redirectToLogIn } from "../util";

export default function SignOut() {
    return (
        <div>
            <h2 className="sr-only">Log Out</h2>
            <div className="d-flex flex-column align-items-center">
                <h3>You have been logged out.</h3>
                <OSUButton type="button" color="blue" ariaLabel="Log In Again" onClick={() => redirectToLogIn()}>Log In</OSUButton>
            </div>
        </div>
    );
}