export const ACTION_STATUS_ERROR = "error";
export const ACTION_STATUS_LOADING = "loading";
export const ACTION_STATUS_SUCCESS = "success";
export const FORM_FEEDBACK_SR_PREFIX = (<span className="sr-only">Form Error:</span>);
export const ORDER_STATUS_ALL = "ALL";
export const ORDER_STATUS_FULFILLED = "FULFILLED";
export const ORDER_STATUS_PENDING = "PENDING";
export const ORDER_STATUS = {
    [ORDER_STATUS_ALL]: "All",
    [ORDER_STATUS_FULFILLED]: "Fulfilled",
    [ORDER_STATUS_PENDING]: "Pending"
};