import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Rewards from "../components/List";
import { getRewardsByYear, REWARD_UPDATE_STATUS, updateReward } from "../../actions-index";
import { buildAction } from "../../util/util";

const mapStateToProps = (state) => {
    const { data: rewards = [], status: rewardsStatus = "" } = state.rewards;
    return {
        rewards,
        rewardsStatus,
        rewardUpdateStatus: state.rewardUpdateStatus
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearRewardUpdateStatus: () => dispatch(buildAction(REWARD_UPDATE_STATUS, "")),
        getRewardsByYear: (year) => dispatch(getRewardsByYear(year)),
        toggleRewardActive: (rewardId, active) => dispatch(updateReward(rewardId, { active }))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rewards));