import Promise from "bluebird";
import { FormFeedback } from "reactstrap";
import { FORM_FEEDBACK_SR_PREFIX } from "./constants";

export function buildAction(type, payload) {
    return { type, payload };
}

export function buildAccessibleFormFeedback(feedback, ariaLive = "polite") {
    return (<FormFeedback aria-live={ariaLive}>{FORM_FEEDBACK_SR_PREFIX} {feedback}</FormFeedback>);
}

export function buildLabelValuePair(value, label) {
    return { label: (label || value), value };
}

export function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        fileReader.onload = () => {
            resolve(btoa(fileReader.result));
        };
        fileReader.onerror = reject;
        fileReader.readAsBinaryString(file);
    });
}