
export const SET_USER = 'SET_USER';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_STATUS = 'USER_STATUS';
export const FETCH_USER = 'FETCH_USER';
export const ORDERS = 'ORDERS';
export const ORDERS_STATUS = 'ORDERS_STATUS';
export const ORDER_STATUS_UPDATE = 'ORDER_STATUS_UPDATE';
export const ORDER_STATUS_UPDATE_STATUS = 'ORDER_STATUS_UPDATE_STATUS';
export const ORDER_ADDRESS_UPDATE = 'ORDER_ADDRESS_UPDATE';
export const ORDER_ADDRESS_UPDATE_STATUS = 'ORDER_ADDRESS_UPDATE_STATUS';
export const REWARDS = 'REWARDS';
export const REWARDS_STATUS = 'REWARDS_STATUS';
export const REWARD_CREATE_STATUS = 'REWARD_CREATE_STATUS';
export const REWARD_IMAGES = 'REWARD_IMAGES';
export const REWARD_IMAGES_STATUS = 'REWARD_IMAGES_STATUS';
export const REWARD_IMAGE_UPLOAD = 'REWARD_IMAGE_UPLOAD';
export const REWARD_IMAGE_UPLOAD_STATUS = 'REWARD_IMAGE_UPLOAD_STATUS';
export const REWARD_UPDATE = 'REWARD_UPDATE';
export const REWARD_UPDATE_STATUS = 'REWARD_UPDATE_STATUS';
export const REWARD_AWARD_KEYS_UPLOAD  = 'REWARD_AWARD_KEYS_UPLOAD';
export const REWARD_AWARD_KEYS_UPLOAD_STATUS = 'REWARD_AWARD_KEYS_UPLOAD_STATUS';
export const REWARD_AWARD_KEYS = 'REWARD_AWARD_KEYS';
export const REWARD_AWARD_KEYS_STATUS  = 'REWARD_AWARD_KEYS_STATUS';
export const REWARD_AWARD_KEYS_UPLOAD_DELETE_STATUS = 'REWARD_AWARD_KEYS_UPLOAD_DELETE_STATUS';
export const REWARD_AWARD_KEYS_UPLOAD_DELETE = 'REWARD_AWARD_KEYS_UPLOAD_DELETE';

/* Export each action file below */
export * from './Authentication/actions';
export * from './Order/actions';
export * from './Reward/actions';