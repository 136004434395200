import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';
import Navbar from 'react-osu-navbar'
import useDarkMode from "../../Common/hooks/useDarkMode";
import Header from "../../Header/containers";
import Main from '../../Main/containers'
import Footer from "./Footer";
import { isEmpty } from "lodash";
import { ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS } from "../../util/constants";

const TIMEOUT = 60000 * (parseInt(process.env.REACT_APP_TIMEOUT) || 120);

function App(props = {}) {
  const { isAuthenticated, setUser, signOut, user } = props;
  const { status: userStatus } = user;
  const { darkMode } = useDarkMode()
  const { pathname } = useLocation()
  const handleOnIdle = event => {
    console.log('user is idle', event)
    console.log('last active', getLastActiveTime())
    signOut()
  }
  const { getLastActiveTime } = useIdleTimer({
    timeout: TIMEOUT,
    onIdle: handleOnIdle
  })

  useEffect(() => {
    if(isEmpty(userStatus) && !["/login", "/logout"].includes(pathname)) setUser(true)
  }, [pathname, setUser, userStatus]);

  return (
      <div className={`App`}>
        <Navbar darkMode={darkMode} />
        {isAuthenticated && <Header /> }
        <Main id="page-content" className="container my-1" />
        <Footer />
      </div>
  );
}

App.defaultProps = {
  isAuthenticated: false,
  user: {
    status: "",
  },
};

App.propTypes = {
  isAuthenticated: PropTypes.bool,
  user: PropTypes.shape({
    status: PropTypes.oneOf([ACTION_STATUS_ERROR, ACTION_STATUS_LOADING, ACTION_STATUS_SUCCESS, ""]),
  }),
  setUser: PropTypes.func,
};

export default App;
