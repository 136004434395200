import moment from "moment";
import { ORDERS, ORDERS_STATUS, ORDER_ADDRESS_UPDATE, ORDER_ADDRESS_UPDATE_STATUS, ORDER_STATUS_UPDATE, ORDER_STATUS_UPDATE_STATUS } from "../actions-index";

export function orders(state = {}, action = {}) {
    const { payload, type } = action;
    switch(type) {
        case ORDERS:
            return {
                ...state, 
                data: payload.map(order => {
                    // convert redemption date to local timezone
                    if(order.redemptionDate) {
                        const redemptionDate = moment.utc(order.redemptionDate);
                        if(redemptionDate.isValid) order.redemptionDate = redemptionDate.local().format("YYYY-MM-DDTHH:mm:ss.SSSZ");
                    }
                    return order;
                })
            }
        case ORDERS_STATUS:
            return { ...state, status: payload };
        case ORDER_ADDRESS_UPDATE:
            // update the shipping address of the order in the list
            return { 
                ...state, 
                data: state.data.map(order => {
                    if(order.id === payload.id) order.shippingAddress = payload.shippingAddress;
                    return order;
                })
            };
        case ORDER_STATUS_UPDATE:
            // remove the order from the list because the status no longer matches the current filter
            return { ...state, data: state.data.filter(order => order.id !== payload) };
        default:
            return state;
    }
}

export function orderStatusUpdateStatus(state = "", action = {}) {
    return (action.type === ORDER_STATUS_UPDATE_STATUS ? action.payload : state);
}

export function orderAddressUpdateStatus(state = "", action = {}) {
    return (action.type === ORDER_ADDRESS_UPDATE_STATUS ? action.payload : state);
}