import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import App from '../components';
import { setUser, authSignOut } from '../../actions-index';

const mapStateToProps = (state, ownProps) => {
    const { authentication } = state
    const { status = '', user = {} } = authentication
    return {
        isAuthenticated: state.isAuthenticated,
        isAuthorized: state.isAuthorized,
        user: { ...user, status }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: (forceRedirect) => dispatch(setUser(forceRedirect)),
        signOut: () => dispatch(authSignOut()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));