function transformUser(obj = {}) {
    const { email, displayName, name, osuid, given_name = '', family_name = '' } = obj;
    const roles = (typeof obj[process.env.REACT_APP_USER_ROLE_ATTRIBUTE_KEY] === "string" ? 
        obj[process.env.REACT_APP_USER_ROLE_ATTRIBUTE_KEY].split(",") : []);
    return {
        isAdmin: roles.includes(process.env.REACT_APP_USER_ROLE_ADMIN),
        email,
        name: displayName ? displayName : name,
        fullName: `${name} ${family_name}`,
        osuid,
        initials: `${given_name && given_name.charAt(0)}${family_name && family_name.charAt(0)}`.toUpperCase(),
        roles
    };
}

export { transformUser }